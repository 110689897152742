body {
  background-color: #171822 !important;
  color: #abfcdd !important;
}
input[type="text"],
input[type="number"],
input[type="email"],
input[type="file"],
input[type="url"],
input[type="password"],
textarea,
select {
  background-color: #21474e !important;
  color: beige !important;
}

input[type="text"]:disabled,
input[type="number"]:disabled,
input[type="email"]:disabled,
input[type="file"]:disabled,
input[type="url"]:disabled,
input[type="password"]:disabled,
textarea:disabled {
  background-color: #0b1c1f;
  color: beige;
}

input::placeholder, input::-webkit-input-placeholder, textarea::placeholder, textarea::-webkit-input-placeholder {
  color: rgb(161, 161, 161) !important;
}

.hidden {
  display: none;
}

iframe { 
  width: 100%;
  aspect-ratio: 16 / 9;
}

.sticky-top {
  background-color: #343a40;
}

#login-form {
  flex-wrap: nowrap;  /* Prevent wrapping */
}

#google-sign-in-button {
  min-width: 130px;
}

input.form-control {
  min-width: 200px; /* Adjust width of input fields as needed */
}
